@tailwind base;
@tailwind components;
@tailwind utilities;

:where(.css-dev-only-do-not-override-4hn1hk).ant-radio-button-wrapper:last-child {
  border-start-end-radius: 2px;
  border-end-end-radius: 2px;
  display: none;
}
textarea {
  background-color: inherit;
}
.calendar-white .ant-picker-body {
  background: white;
}

:where(.css-dev-only-do-not-override-4hn1hk).ant-picker-calendar
  .ant-picker-calendar-header
  .ant-picker-calendar-year-select {
  display: none;
}
[aria-label='Select All'] {
  display: none;
}
.scrollbar::-webkit-scrollbar {
  width: 10px;
  height: 20px;
}

.scrollbar::-webkit-scrollbar-track {
  border-radius: 100vh;
  background: #ccd4f9;
}

.scrollbar::-webkit-scrollbar-thumb {
  background: #91a1f1;
  border-radius: 100vh;
  border: 3px solid #ccd4f9;
}

.scrollbar::-webkit-scrollbar-thumb:hover {
  background: #c0a0b9;
}

.scrollbar-blue::-webkit-scrollbar {
  width: 10px;
  height: 20px;
}

.scrollbar-blue::-webkit-scrollbar-track {
  border-radius: 100vh;
  background: rgb(218, 223, 246);
}

.scrollbar-blue::-webkit-scrollbar-thumb {
  background: #5d90cb;
  border-radius: 100vh;
  border: 3px solid #ccd4f9;
}

.scrollbar-blue::-webkit-scrollbar-thumb:hover {
  background: #cce2fc;
}
input {
  background-color: transparent;
}
input[type='date']:required:invalid::-webkit-datetime-edit {
  color: red;
}
input[type='date']:focus::-webkit-datetime-edit {
  color: black !important;
}
[data-overlay-container='true'] {
  height: 100%;
  width: 100%;
}
.textLogo {
  color: #10427b;
}
.elementToFadeInAndOut {
  opacity: 1;
  animation: fade 1s infinite linear;
}
@keyframes fade {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
@property --num {
  syntax: '<integer>';
  initial-value: 0;
  inherits: false;
}
.counter {
  text-align: left;
  font-size: 14px;
  animation: counter 0s ease-in-out;
  counter-reset: num var(--num);
  --num: var(--finalnum);
}
.counter::before {
  content: counter(num);
}
@keyframes counter {
  from {
    --num: 0;
  }
  to {
    --num: var(--finalnum);
  }
}
.backfront {
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100vh;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.progress-9 {
  color: #f5faff;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100vh;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  width: 230px;
  height: 230px;
  position: absolute;
  text-align: center;
  --r1: 154%;
  --r2: 68.5%;

  border-radius: 50%;
  background: radial-gradient(var(--r1) var(--r2) at top, #0000 79.5%, #83baf8 80%),
    radial-gradient(var(--r1) var(--r2) at bottom, #83baf8 79.5%, #0000 80%),
    radial-gradient(var(--r1) var(--r2) at top, #0000 79.5%, #83baf8 80%), #cccccc00;
  background-size: 50.5% 220%;
  background-position: -100% 0%, 0% 0%, 100% 0%;
  background-repeat: no-repeat;
  animation: p9 1s infinite linear;
}
@keyframes p9 {
  33% {
    background-position: 0% 33%, 100% 33%, 200% 33%;
  }
  66% {
    background-position: -100% 66%, 0% 66%, 100% 66%;
  }
  100% {
    background-position: 0% 100%, 100% 100%, 200% 100%;
  }
}
